import React from "react"
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet"
import {Link} from 'gatsby';

export default function Recruiters() {

    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>How it works | Skillmeter</title>
        </Helmet>
                
        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
              How it works
            </h2>
            <p className="mt-6 text-lg font-normal text-gray-600 ">
            2-minute video overview on how Skillmeter works
          </p>            
          </div>
  
          <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                <iframe src="//player.vimeo.com/video/67395587?title=0&amp;byline=0&amp;portrait=0" width="960" height="541" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
  
      {/* CTA */}
      <section class="py-4 bg-blue-50 sm:py-6 lg:py-8">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="text-center">
          <h2 class="mx-auto text-4xl font-bold text-gray-900 ">
            Ready to give us a try?  The first 14 days are free.
          </h2>


            <Link to={'/pricing'} class="mt-4 inline-flex items-center justify-center px-6 py-3 text-base font-bold bg-smcolor text-white rounded-xl focus:outline-none hover:bg-smcolor-dark  hover:text-white " role="button">
              See Plans &amp; Pricing
            </Link>

        </div>
      </div>
    </section>

        <Footer />
      </>
    )
}